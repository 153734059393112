html {
  height: 100vh;
}

body {
  padding: 0;
  max-height: 100%;
  max-width: 100%;
}

button a {
  color: white;
}

.form-settings {
  width: 85%;
}

.detail-settings > div {
  width: 100%;
}

.detail-settings h3 {
  font-size: 20px;
  font-family: var(--fuenteBold);
  color: var(--color-accent);
  margin-top: -22px;
  margin-left: 20px;
}

.detail-settings {
  width: 100%;
  padding: 0.5em 0.5em 0;
  margin-top: 10px;
}

.loading-message {
  font-size: 20;
  color: var(--color-accent);
  font-family: var(--fuenteBold);
}

.controls-sticky {
  position: sticky;
  padding: 10px 0px 0px 0px;
  width: 100%;
  background: white;
  z-index: 4;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

#codigo-solicitud {
  margin-top: 0;
  margin-bottom: 0;
}

.containerAdm ul li {
  list-style: none;
}

.mb-9 {
  margin-bottom: 9%;
}

.p-0 {
  padding: 0;
}

button.delete {
  border: none;
  background: transparent;
  color: #9e3a3a;
}

.main-admin-bar .search-button {
  padding-top: 5px;
  border-radius: 10px;
  color: var(--color-primary);
  border: solid 1px #9ea7ad;
  background: white;
}

.mainColumn {
  font-family: var(--fuenteBold);
}

.viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(228, 228, 228, 0.663);
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
}

.viewer-container > button {
  position: absolute;
  top: 3%;
  right: 3%;
  border-radius: 50%;
  border: none;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 40px;
  background-color: var(--color-accent);
  color: white;
  font-size: 1.5rem;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pg-viewer-wrapper .photo-viewer-container {
  width: 100vw !important;
}

.pg-viewer-wrapper img {
  width: max-content !important;
  height: max-content !important;
  max-height: 100%;
  max-width: 100%;
  border-radius: 20px;
}

.form-group {
  margin-bottom: -0.5rem;
  position: relative;
}

.select-async-container .css-1s2u09g-control {
  height: 40px;
  border-radius: 10px;
  border: none !important;
  pointer-events: none;
  color: #9f9f9f;
}

.css-1s2u09g-control.abled {
  border: 1px solid #9f9f9f;
  pointer-events: all;
  color: black;
}

#dashboard {
  font-family: var(--fuenteBold);
  color: #545454;
}

.dashboard-control {
  display: block;
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin-left: auto;
  padding: 6px 12px;
  border-radius: 10px;
  border: solid 1px #c1c1c1;
  font-family: var(--fuenteLight);
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
}

.welcome-dashboard-text {
  background: white;
  border-radius: 10px;
  padding: 22px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

#date-range-container {
  background-color: var(--admHeaderSidebar);
  padding: 12px;
  border-radius: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

#date-range-container > div {
  display: flex;
  gap: 20px;
  align-items: center;
  visibility: hidden;
  pointer-events: none;
  margin-left: auto;
}

#date-range-container .dashboard-control {
  margin-left: 0;
}

#date-range-container > div.visible {
  visibility: visible;
  pointer-events: all;
}

.grid-item-large {
  grid-column-start: 1;
  grid-column-end: 3;
}

.section-card {
  background-color: white;
  /* background-color: #fbfbfc; */
  min-height: 80%;
  border-radius: 10px;
  border: none;
  margin-bottom: 50px;
  padding: 10px 35px 30px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.graphic-card {
  padding: 5px;
  overflow-x: auto;
  overflow-y: hidden;
}

.graphic-card::-webkit-scrollbar-track {
  background-color: rgb(254, 254, 254);
  border-radius: 10px;
}

.graphic-card::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.graphic-card::-webkit-scrollbar {
  height: 10px;
}

#count-solicitudes-container {
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 60px;
  /* flex-wrap: nowrap; */
}

#count-solicitudes-container li {
  box-shadow: rgba(93, 116, 155, 0.25) 0px 1px 1px,
    rgba(32, 61, 112, 0.13) 0px 0px 1px 1px;
  background-color: white;
  margin: 0px;
  padding: 15px 10px;
  position: relative;
  border-radius: 15px;
  border-left: 2px solid;
  border-color: var(--color-accent);
  max-width: 100%;
  min-width: 40%;
  /* min-width: 17%; */
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  gap: 20px;
}

#count-solicitudes-container li > div {
  position: absolute;
  width: fit-content;
  bottom: 10px;
  left: 35%;
}

#count-solicitudes-container .material-icons-outlined {
  font-size: 30px;
  color: var(--color-primary);
}

.number-card {
  padding-top: 2px;
  display: flex;
}

.big-number {
  font-size: 28px;
}

.dboard-img {
  width: 30%;
  margin-right: 20px;
}

.short-text {
  width: 100px;
  height: 65px;
  white-space: normal;
}

.d-flex.bottom-align {
  align-items: flex-end;
  flex-wrap: wrap;
}

.col-40 {
  width: 45%;
}

.invisibleBtn {
  display: none;
}

.modBtn {
  background-color: transparent;
  border: none;
  color: var(--color-accent);
  display: flex;
  align-items: end;
  gap: 5px;
}

.no-results-container {
  color: var(--color-bg-variant);
  font-family: var(--fuenteBold);
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 5%;
  padding: 4% 30%;
}

.containerAdm {
  background-color: var(--adm-bg);
  padding-top: 70px;
  padding-left: 240px;
  min-width: fit-content;
  height: 100vh;
  box-sizing: border-box;
}

.button-alternative {
  border: solid 1px var(--color-primary);
  border-radius: 7px;
  font-family: var(--fuenteMedium);
  color: var(--color-primary);
  background: transparent;
  padding: 10px;
  font-size: 14px;
}

.file-container > button {
  border-radius: 10px;
  border: none;
  height: 30px;
  width: 30px;
  color: var(--color-accent);
  background-color: transparent;
  margin: 0 2px 0 0;
  padding: 0;
}

.file-container > button i,
.file-container > a i {
  color: var(--color-accent) !important;
}

.content-file {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  position: relative;
  padding: 7px 12px;
}

.content-file span {
  font-family: var(--fuenteMedium);
  color: var(--color-primary);
  font-size: 12px;
}

summary::marker {
  color: #9f9f9f;
}

.details {
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 0.5em 0.5em 0;
  margin-top: 10px;
}

.invisible-check {
  display: none !important;
}

.rounded-label-checkbox {
  padding-left: 1.6em;
  position: relative;
  left: 1.2em;
  bottom: 1.1em;
}

.rounded-label-checkbox:before {
  content: "";
  border: solid 2px rgb(124, 124, 124);
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  left: 0;
}

.invisible-check[type="checkbox"]:checked + label:before {
  border: none;
  content: "";
  color: #00b4a2;
  font-family: "material-icons";
  font-size: 17px;
}

.fsize-13 {
  font-size: 13px;
}

.pl-4 {
  padding-left: 4%;
}

.sideBar button:active,
.sideBar a:active {
  background-color: var(--color-secondary);
}

.sideBar button:hover,
.sideBar a:hover {
  background-color: #eeeefc;
}

.sideBar button:focus,
.sideBar a:focus {
  background-color: #cfcfff;
}

.loadingParent {
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  gap: 5%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingParent.loadingHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 1s ease;
}

.loadingParent.back {
  z-index: 5;
}

.loadingHidden .loaderGeneral,
.loadingHidden .loader {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 1s ease;
}

.loader {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 55%;
}

.loaderGeneral {
  position: absolute;
}

.config-title .text-bold {
  font-weight: bold;
  font-size: 23px;
  color: var(--color-accent);
}

@media (min-width: 992px) and (max-width: 1265px) {
  .short-text {
    margin-top: 35px;
  }
}

@media (max-width: 575.98px) {
  .controls-sticky {
    top: 8%;
  }

  .mainColumn {
    text-align: left;
  }

  .pg-viewer-wrapper img {
    max-width: 100%;
    border-radius: 20px;
  }

  #dashboard {
    flex-direction: column;
  }

  .col-40 {
    width: 100%;
  }

  .no-results-container {
    width: 80%;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
  }

  .no-results-container h1 {
    font-size: 35px;
  }

  .sideBar .btnCampos .material-icons.showOptions {
    left: 30%;
  }

  button.btnCampos {
    padding-left: 8%;
  }

  .containerAdm {
    padding-left: 0px;
    padding-top: 70px;
    min-height: 100vh;
  }

  .loader {
    position: static;
  }

  .sideBar button:active,
  .sideBar a:active {
    background-color: var(--color-secondary);
  }

  .sideBar button:hover,
  .sideBar a:hover {
    background-color: #eeeefc;
  }

  .sideBar button:focus,
  .sideBar a:focus {
    background-color: #cfcfff;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .modBtn {
    padding-right: 0px;
  }

  .main-admin {
    max-width: 100vw;
  }

  .main-admin .table-adm {
    max-width: 90vw;
  }

  .main-admin .table-adm td,
  .table-adm thead tr td {
    font-size: 20px;
  }

  .css-qc6sy-singleValue {
    font-size: 17px;
  }

  .mainContentDetail {
    margin-top: 0;
  }

  .mainColumn {
    text-align: left;
  }

  .pg-viewer-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  #dashboard {
    flex-direction: column;
  }

  .header h1 {
    margin-bottom: 0;
  }

  .sideBar .btnCampos .material-icons.showOptions {
    left: 30%;
  }

  button.btnCampos {
    padding-left: 8%;
  }

  .no-results-container {
    width: 80%;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
  }

  .no-results-container h1 {
    font-size: 35px;
  }

  .col-40 {
    width: 100%;
  }

  #dashboard .section p {
    font-size: 25px;
  }

  .containerAdm {
    padding-left: 0px;
    padding-top: 70px;
    min-height: 100vh;
  }

  .loader {
    position: static;
  }

  .content-file span {
    font-size: 18px;
  }
}
